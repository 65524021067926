import { ref, reactive }  from 'vue'

const AdvertisementStore = {
  namespaced: true,
  state: {
    currentStep: 0,
    advertisement: {
      modelType: ref('personal'),
      brand: reactive({}),
      model: reactive({}),
      body: reactive({}),
      seatsCount: reactive({}),
      gearbox: reactive({}),
      wheelDrive: reactive({}),
      fuel: reactive({}),
      displacement: reactive({}),
      enginePower: reactive({}),
      carState: reactive({}),
      ridesSinceYear: reactive({}),
      countryOrigin: reactive({}),
      mileage: ref(undefined),
      vin: ref(undefined),
      firstOwner: false,
      subtitle: ref(undefined),
      advertisementText: ref(undefined),
      carEquipments: reactive([]),
      customEquipments: reactive([]),
      price: ref(undefined),
      vatDeduction: ref(undefined),
      authorName: ref(undefined),
      contactEmail: ref(undefined),
      contactPhone: ref(undefined),
      region: ref(undefined),
      authorDescription: ref(undefined),
      images: reactive([]),
    }
  },

  mutations: {
    modelType(state, value) {
      state.advertisement.modelType = value
    },

    updateCurrentStep(state, value) {
      state.currentStep = value
    },

    selectBrand(state, brand) {
      state.advertisement.brand = brand
    },

    selectModel(state, model) {
      state.advertisement.model = model
    },

    selectBody(state, body) {
      state.advertisement.body = body
    },

    selectSeatsCount(state, seatsCount) {
      state.advertisement.seatsCount = seatsCount
    },

    selectGearbox(state, gearbox) {
      state.advertisement.gearbox = gearbox
    },

    selectWheelDrive(state, wheelDrive) {
      state.advertisement.wheelDrive = wheelDrive
    },

    selectFuel(state, fuel) {
      state.advertisement.fuel = fuel
    },

    selectDisplacement(state, displacement) {
      state.advertisement.displacement = displacement
    },

    selectEnginePower(state, power) {
      state.advertisement.enginePower = power
    },

    selectCarState(state, carState) {
      state.advertisement.carState = carState
    },

    selectRidesSinceYear(state, ridesSinceYear) {
      state.advertisement.ridesSinceYear = ridesSinceYear
    },

    selectCountryOrigin(state, countryOrigin) {
      state.advertisement.countryOrigin = countryOrigin
    },

    selectFirstOwner(state, value) {
      state.advertisement.firstOwner = value
    },

    // selectVatDeduction(state, value) {
    //   state.advertisement.vatDeduction = value
    // },

    mileage(state, value) {
      state.advertisement.mileage = value
    },

    vin(state, value) {
      state.advertisement.vin = value
    },

    firstOwner(state, value) {
      state.advertisement.firstOwner = value
    },

    subtitle(state, value) {
      state.advertisement.subtitle = value
    },

    price(state, value) {
      state.advertisement.price = value
    },

    vatDeduction(state, value) {
      state.advertisement.vatDeduction = value
    },

    advertisementText(state, value) {
      state.advertisement.advertisementText = value
    },

    carEquipments(state, equipments) {
      state.advertisement.carEquipments = equipments
    },

    customEquipments(state, equipments) {
      state.advertisement.customEquipments = equipments
    },

    authorName(state, value) {
      state.advertisement.authorName = value
    },

    contactEmail(state, value) {
      state.advertisement.contactEmail = value
    },

    contactPhone(state, value) {
      state.advertisement.contactPhone = value
    },

    region(state, value) {
      state.advertisement.region = value
    },

    authorDescription(state, value) {
      state.advertisement.authorDescription = value
    },

    images(state, images) {
      state.advertisement.images = [...state.advertisement.images, ...images]
    },

    removeImage(state, index) {
      state.advertisement.images.splice(index, 1)
    },

    reset(state) {
      state.currentStep = 0;
      state.advertisement = {
        modelType: ref('personal'),
        brand: reactive({}),
        model: reactive({}),
        body: reactive({}),
        seatsCount: reactive({}),
        gearbox: reactive({}),
        wheelDrive: reactive({}),
        fuel: reactive({}),
        displacement: reactive({}),
        enginePower: reactive({}),
        carState: reactive({}),
        ridesSinceYear: reactive({}),
        countryOrigin: reactive({}),
        mileage: ref(undefined),
        vin: ref(undefined),
        firstOwner: ref(undefined),
        subtitle: ref(undefined),
        advertisementText: ref(undefined),
        carEquipments: reactive([]),
        customEquipments: reactive([]),
        price: ref(undefined),
        vatDeduction: ref(undefined),
        authorName: ref(undefined),
        contactEmail: ref(undefined),
        contactPhone: ref(undefined),
        region: ref(undefined),
        authorDescription: ref(undefined),
        images: reactive([]),
      }
    }
  },
}

export default AdvertisementStore
