<template>
  <section class="section" id="step-1">
    <h3>Parametry vozu</h3>
    <div class="row gx-4">
      <div class="col-lg-12 pt-lg-2 pt-2 pb-lg-3 pb-2">
        <div class="pt-2">
          <div class="form-check form-check-inline">
            <input
              v-model="modelType"
              class="form-check-input"
              type="radio"
              name="type"
              id="type-1"
              value="personal"
            />
            <label class="form-check-label" for="type-1">Osobní</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              v-model="modelType"
              class="form-check-input"
              type="radio"
              name="type"
              id="type-2"
              value="utility"
            />
            <label class="form-check-label" for="type-2">Užitkové</label>
          </div>
        </div>
      </div>
      <FormSelect
        id="brand-select"
        modalId="single-brand-modal"
        label="Značka"
        :is-required="true"
        :selected-option="selectedBrand"
      />
      <FormSelect
        id="model-select"
        modalId="single-model-modal"
        label="Model"
        :is-required="true"
        :selected-option="selectedModel"
        :is-disabled="modelInputDisabled"
      />
      <FormSelect
        id="body-select"
        modalId="single-body-modal"
        label="Karosérie"
        :is-required="true"
        :selected-option="selectedBody"
      />
      <FormSelect
        id="seats-count-select"
        modalId="single-seats-count-modal"
        label="Počet sedadel"
        :is-required="true"
        :selected-option="selectedSeatsCount"
      />
      <FormSelect
        id="gearbox-select-select"
        modalId="single-gearbox-modal"
        label="Převodovka"
        :is-required="true"
        :selected-option="selectedGearbox"
      />
      <FormSelect
        id="wheel-drive-select-select"
        modalId="single-wheel-drive-modal"
        label="Pohon"
        :is-required="true"
        :selected-option="selectedWheelDrive"
      />
      <FormSelect
        id="fuel-select-select"
        modalId="single-fuel-modal"
        label="Palivo"
        :is-required="true"
        :selected-option="selectedFuel"
      />
      <FormSelect
        id="displacement-select"
        modalId="single-displacement-modal"
        label="Objem"
        unit="ccm"
        :is-required="true"
        :selected-option="selectedDisplacement"
      />
      <FormSelect
        id="engine-power-select"
        modalId="single-engine-power-modal"
        label="Výkon motoru"
        unit="kW"
        :is-required="true"
        :selected-option="selectedEnginePower"
      />
    </div>

    <h3 class="mt-5">Stav a historie</h3>
    <div class="row gx-4">
      <FormSelect
        id="car-state-select"
        modalId="single-car-state-modal"
        label="Stav vozu"
        :is-required="true"
        :selected-option="selectedCarState"
      />
      <FormSelect
        id="rides-since-year-select"
        modalId="single-rides-since-year-modal"
        label="Rok uvedení do provozu"
        :is-required="true"
        :selected-option="selectedRidesSinceYear"
      />
      <FormSelect
        id="country-origin-select"
        modalId="single-country-origin-modal"
        label="Země původu"
        :is-required="true"
        :selected-option="selectedCountryOrigin"
      />
      <FormBoolean
        v-model="firstOwner"
        label="První majitel"
        id="first-owner"
        :is-required="true"
        class="d-none"
      />
      <FormInput
        v-model="mileage"
        label="Stav tachometru (km)"
        input-type="text"
        input-mode="numeric"
        masked-type="number"
        :is-required="true"
        css-style="col-xl-4 col-lg-6 pt-lg-2 pt-2 pb-lg-3 pb-2"
      />
      <FormInput
        v-model="vin"
        label="VIN"
        :max-length="17"
        :pattern="/(?=.*\d|=.*[A-Z])(?=.*[A-Z])[A-Z0-9]{17}/"
        input-type="text"
        :is-required="true"
        masked-type="vin"
        :show-length-counter="true"
        css-style="col-xl-4 col-lg-6 pt-lg-2 pt-2 pb-lg-3 pb-2"
      />
    </div>
  </section>
</template>

<script>
import FormSelect from '../../FormSelect.vue';
import FormBoolean from '../../FormBoolean.vue';
import FormInput from '../../FormInput.vue';
import { isEmpty } from 'lodash';

export default {
  name: 'BaseInfoStep',

  components: {
    FormSelect,
    FormBoolean,
    FormInput,
  },

  computed: {
    modelType: {
      get() {
        return this.$store.state.AdvertisementStore.advertisement.modelType;
      },
      set(value) {
        this.$store.commit('AdvertisementStore/modelType', value);
      },
    },

    mileage: {
      get() {
        return this.$store.state.AdvertisementStore.advertisement.mileage;
      },
      set(value) {
        this.$store.commit('AdvertisementStore/mileage', value);
      },
    },

    vin: {
      get() {
        return this.$store.state.AdvertisementStore.advertisement.vin;
      },
      set(value) {
        this.$store.commit('AdvertisementStore/vin', value);
      },
    },

    firstOwner: {
      get() {
        return this.$store.state.AdvertisementStore.advertisement.firstOwner;
      },
      set(value) {
        this.$store.commit('AdvertisementStore/firstOwner', value);
      },
    },

    selectedBrand() {
      return this.$store.state.AdvertisementStore.advertisement.brand;
    },
    selectedModel() {
      return this.$store.state.AdvertisementStore.advertisement.model;
    },
    selectedBody() {
      return this.$store.state.AdvertisementStore.advertisement.body;
    },
    selectedSeatsCount() {
      return this.$store.state.AdvertisementStore.advertisement.seatsCount;
    },
    selectedGearbox() {
      return this.$store.state.AdvertisementStore.advertisement.gearbox;
    },
    selectedWheelDrive() {
      return this.$store.state.AdvertisementStore.advertisement.wheelDrive;
    },
    selectedFuel() {
      return this.$store.state.AdvertisementStore.advertisement.fuel;
    },
    selectedDisplacement() {
      return this.$store.state.AdvertisementStore.advertisement.displacement;
    },
    selectedEnginePower() {
      return this.$store.state.AdvertisementStore.advertisement.enginePower;
    },
    selectedCarState() {
      return this.$store.state.AdvertisementStore.advertisement.carState;
    },
    selectedRidesSinceYear() {
      return this.$store.state.AdvertisementStore.advertisement.ridesSinceYear;
    },
    selectedCountryOrigin() {
      return this.$store.state.AdvertisementStore.advertisement.countryOrigin;
    },
    modelInputDisabled() {
      return isEmpty(this.selectedBrand);
    },
  },
};
</script>
